@use '../../../configs/styles/defaultStyle.scss' as default;

.popup-container {
  height: max-content;
  width: max-content;
  overflow: auto;
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: default.$main-content-background-color;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  visibility: hidden;

  &.with-padding {
    padding: 35px 41px;
  }

  &.show {
    visibility: visible;
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 2;
  }
}
