.mark-noshow-modal-progress-button {
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 0.5rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  color: white;
  font-weight: 600;
  background-color: #1570ef;

  &:disabled {
    background-color: #b2ddff;
  }

  > .noshow-text {
    z-index: 2;
    position: relative;
  }

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: #1570ef;
    border-radius: 6px;
    color: white;
  }

  @keyframes progress {
    0% {
      left: -100%;
    }
    100% {
      left: 0%;
    }
  }

  &.progress::before {
    animation: progress 5s linear forwards;
  }
}
