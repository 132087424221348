@use '../../../../configs/styles/defaultStyle.scss' as default;

.chat-window-container {
  height: 525px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: default.$main-content-background-color;
  border-radius: default.$main-content-border-radius;
  -webkit-box-shadow: 5px 1px 67px -3px rgba(0, 0, 0, 0.56);
  -moz-box-shadow: 5px 1px 67px -3px rgba(0, 0, 0, 0.56);
  box-shadow: 5px 1px 67px -3px rgba(0, 0, 0, 0.56);

  &.full-view {
    width: 804px;
  }

  &.chat-view {
    width: 500px;
  }

  .contacts-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: inherit;
    width: 40%;
    border-right: 1px solid default.$divider-color;

    .contacts-header-container {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      width: 100%;
      // height: 113px;

      .contacts-header {
        width: 100%;

        border-bottom: 1px solid default.$divider-color;
        height: 75px;
        padding: 25px;
      }
      .search-bar-container {
        padding-bottom: 5px;
        width: 90%;
      }
    }

    .contacts-tabs-container {
      width: 100%;
      height: calc(100% - 138px);
    }
  }

  .chat-container {
    &.full-view {
      width: 60%;
    }

    &.chat-view {
      width: 100%;
    }
  }
}
