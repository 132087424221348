.validation-strength-wrapper {
  width: 100%;
  .validation-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
    .validation-icon {
      width: 8px;
    }
    .validation-text {
      font-size: 12px;
    }
  }
}
