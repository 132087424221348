.image-content {
  width: max-content;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 1rem;
  }
  .attached-msg {
    padding: 12px;
    width: 100%;
  }
}
