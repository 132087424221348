.attachment-preview {
  position: absolute;
  bottom: 100%;
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
  border-top: 1px solid #e9eaeb;
  z-index: 3;
  padding: 16px 5px 15px;
  .attachment-container {
    position: relative;
    display: flex;
    gap: 5px;
    box-shadow: 0px 1px 8px 6px #0000000a;
    padding: 2px;
    border-radius: 10px;
  }
  .file-detail {
    margin: auto;
    width: 110px;
    span {
      &.typography {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .discard-preview {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -4px;
    right: -10px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    box-shadow: 0px 1px 8px 6px #0000000a;
  }
}
