.cancellation-warning-wrapper {
  padding: 32px 27px;
  margin: auto;
  width: 38vw;
  height: 32vh;
  min-width: 502px;
  min-height: 262px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .cancellation-warning-msg {
    width: 84%;
    margin: 0 auto;
    margin-bottom: 12px;
    padding: 12px 0;
  }
  .cancellation-warning-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
}
