.assignment-message {
  min-height: 200px;
  width: 230px;
  box-sizing: border-box;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;

  .assignment-image-container {
    height: 90px;
    width: 100%;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 1rem;
    }
  }

  .assignment-msg-contents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    .msg-notes {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
