.multi-dropdown-wrapper {
  height: 2.75rem;
  min-width: 132px;
  box-sizing: border-box;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 14px;
  cursor: pointer;
  position: relative;
  .placeholder-container {
    width: 100%;
    user-select: none;
    display: flex;
    justify-content: start;
    padding: 10px;
    align-items: center;
    height: 2.75rem;
    gap: 8px;
    span {
      &.typography {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .dropdown-base {
    position: absolute;
    width: 100%;
    padding: 10px 15px;
    background-color: #fff;
    box-shadow: 0px 0px 24px rgba(26, 38, 49, 0.08);
    border-radius: 0.5rem;
    max-height: 400px;
    overflow-y: scroll;
    z-index: 1;
    .menu-item {
      min-height: 2.75rem;
      border-bottom: 1px solid #dde4f0;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      display: flex;
      align-items: center;
    }
    .submenu-item {
      min-height: 2.75rem;
      display: flex;
      align-items: center;
      padding-left: 15px;
      justify-content: start;
      gap: 10px;
    }
  }
}
