.no-results {
  height: 281px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  .image-container {
    margin-bottom: 1rem;
  }

  span {
    text-align: center;
  }

  .no-results-action {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
