@use '../../../configs/styles/defaultStyle.scss' as default;

.tabs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  .tab-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .tab-header {
      border-bottom: 1px solid default.$divider-color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 12px;
      cursor: pointer;
      transition: border ease-in-out 0.3s;

      &.selected {
        border-bottom: 1px solid default.$primary-color;
      }
      .tab-name-wrapper {
        width: 100%;
        &.left {
          text-align: left;
        }
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }
}
