@use '../../../config/styles/mixins.scss';
.form-item {
  @include mixins.flex-col;
  gap: 0.5rem;
  width: 100%;
  margin-top: 10px;

  &.fixed-container {
    height: 200px;
    overflow-y: auto;
  }

  .form-item-row {
    @include mixins.flex-row(space-between, center);
    gap: 1rem;
    width: 100%;

    .form-item-row-item {
      @include mixins.flex-col;
      gap: 0.5rem;
      width: 100%;
    }
  }
}
.client-button-popup {
  width: 100%;
  margin-top: 32px;
  padding-bottom: 32px;
}
.date-container-popup {
  width: 49%;
}
.selection-message-popup {
  margin-top: 24px;
  margin-bottom: 48px;
}
