@use '../../../../configs/styles/defaultStyle.scss' as default;

.chat-controls-container {
  min-height: 71px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  border-top: 1px solid default.$divider-color;
  position: relative;

  .message-textbox-container {
    width: 100%;
  }

  .send-icon-container {
    height: 26px;
    width: 26px;
    cursor: pointer;
    img {
      height: 26px;
    }
    &.disable {
      color: grey;
    }
  }

  .message-input-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    position: relative;
    padding: 1rem;

    img.attachment {
      cursor: pointer;
    }

    img.record-voice {
      position: absolute;
      right: 15%;
      cursor: pointer;
    }

    textarea.message-input {
      min-width: fit-content;
      height: 2.5rem;
      background-color: #e6edf2;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 22px;
      border: none;
      padding: 0.55rem 2.5rem 0.55rem 2.5rem;
      outline: none;
      width: 100%;
      box-sizing: border-box;
      border-radius: default.$main-content-border-radius;
      resize: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: none;
      }

      ::placeholder {
        color: default.$secondary-color;
      }
    }
  }
}
