@mixin flex($justifyContent: space-between, $alignItems: center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}
@mixin followUpBoxBorder($border-color: rgba(208, 213, 221, 1)) {
  border: 1px solid $border-color;
  border-radius: 8px;
}
.recommendation {
  @include followUpBoxBorder();
  @include flex();
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 1rem;
  .recommendation-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.2rem;
    .button-container {
      min-width: 152px;
    }
    .section-title {
      padding: 0.1rem 0;
      .pill-icon {
        margin-right: 0;
      }
    }
  }
  .check-list {
    @include flex(flex-start, flex-start);
    flex-direction: column;
    gap: 1rem;
    .checkboxField {
      @include flex();
      gap: 1.2rem;
    }
  }
}
