@use '../../../configs/styles/defaultStyle.scss' as default;

.intro-container {
  width: 50%;
  background-color: default.$primary-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .circle-fade {
    position: absolute;
    overflow: hidden;
    border: 10vw solid #f5e5e5;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: -60%;
    right: 35%;
    opacity: 0.05;
  }

  .content-container {
    margin-top: 40%;
    margin-left: 25%;
    width: 50%;

    .quote-icon {
      margin-bottom: 5px;
    }

    .intro {
      text-align: left;
      width: 80%;
    }

    img.right-angle {
      margin-left: 15vw;
      position: absolute;
    }
  }

  .rect-arrow {
    position: absolute;
    background-image: url('../../../assets/rect-arrow.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 15%;
    height: 20%;
    bottom: 0;
    right: 0;
  }
}
