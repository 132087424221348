@use '../../../configs/styles/defaultStyle.scss' as default;

.textarea-container {
  width: 100%;
  height: 100%;
  position: relative;

  textarea.text-area {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    min-width: 400px;
    padding: 13px 17px;
    color: default.$primary-color;
    outline: none;
    border: 1px solid default.$divider-color;
    font-family: Poppins;
    box-sizing: border-box;
    resize: none;
    font-size: 0.75rem;
    position: relative;
    background-color: rgba(0, 0, 0, 0);

    ::placeholder {
      color: default.$secondary-color;
    }
  }
  textarea.text-area-selected {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    min-width: 400px;
    padding: 13px 17px;
    color: default.$primary-color;
    outline: none;
    border: rgba(0, 0, 0, 0.4);
    font-family: Poppins;
    box-sizing: border-box;
    resize: none;
    font-size: 0.75rem;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
  }

  .char-counter {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}
