.care-referral-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #dadce8;
  padding: 16px;
  .button-container {
    span {
      &.typography {
        color: white;
      }
    }
    &.raised {
      img {
        width: 16px;
        height: 16px;
        &.icon {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }
  .care-referral-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    .care-referral-icon {
      width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 4px;
      background: #fff2df;
      &.sos {
        background: #fff1f1;
        .referral-icon {
          filter: invert(59%) sepia(63%) saturate(353%) hue-rotate(314deg)
            brightness(89%) contrast(121%);
        }
      }
    }
  }
  &.sos {
    border: 1px solid #fae8e8;
    background: #fffbfb;
  }
}
