.accordion-dropdown-container {
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 12px;
  position: relative;
  z-index: 10;
  min-height: 340px;

  .accordion-content-container {
    width: 100%;
    border-bottom: 1px solid #f2f4f7;
  }
  .accordion-content-container:last-child {
    border-bottom: none;
  }
  .presenting-issue-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
    cursor: pointer;
  }
  .presenting-issue-heading-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding: 10px;
    color: #1a2631;
  }
  .issue-container {
    padding-top: 6px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 16px;
    cursor: pointer;
    overflow: hidden;
  }
  .issue-container:hover {
    background: #fafbfc;
    border: 1px solid #f0f2f4;
    border-radius: 8px;
  }
  .issue-value-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #1a2631;
  }
  .issue-subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #b3b3b3;
  }
}
.dropdown-search {
  width: 100%;
  margin: 0 auto;
}
