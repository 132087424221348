.call-wrapper {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background: #121a24;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .call-section {
    width: 100%;
    height: 100%;
    .joining-call {
      background: #2b3f56;
      width: 100%;
      height: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 3rem;
      box-sizing: border-box;
      border-radius: 4px;
      margin: auto;
      gap: 1rem;
      border-radius: 16px;
    }
    .start-screen {
      height: 100%;
    }
    .haircheck {
      margin: auto;
      height: calc(100vh - 80px);
      overflow: scroll;
    }
    .video-wrapper {
      background: #1f2d3d;
      .tile-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px;
        height: 100%;
        .local-video {
          position: relative;
          width: 25%;
          right: 0;
          top: 0;
          .video-player {
            video {
              height: auto;
            }
          }
        }
        .remote-video {
          height: 100%;
        }
      }
      .screen-shared {
        display: block;
        width: 25%;
        .video-player {
          video {
            height: auto;
          }
        }
        .local-video {
          position: relative;
          width: 100%;
          height: fit-content;
        }
        .remote-video {
          height: fit-content;
          width: 100%;
        }
      }
    }
    .screen-shared-wrapper {
      display: flex;
    }
  }
  .video-tray {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
  }
  .error-section {
    background: #2b3f56;
    width: 480px;
    height: 270px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem;
    box-sizing: border-box;
    border-radius: 4px;
    margin: auto;
    gap: 1rem;
    border-radius: 16px;

    .retry {
      &:hover {
        transform: scale(1.025);
      }
    }
  }
}
