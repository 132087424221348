.case-note-header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .session-container {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
}
