.case-notes-container {
  width: 500px;
  height: 100%;
  overflow: hidden auto;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container-selected {
  width: 500px;
  height: 100%;
  overflow: none;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.controls-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .control {
    height: 50px;
    width: 50px;
    cursor: pointer;
  }
}

.case-notes-header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 21px 21px 33px 25px;
}

.case-note-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-start;
  align-items: center;

  .case-note-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .question {
      width: 100%;
      margin-bottom: 9px;
    }

    .answer {
      width: 100%;
      height: 132px;
    }
  }
}

.nested-select {
  position: relative;
  width: 150px;
}

.nested-select select {
  display: none;
}

.nested-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.nested-select-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nested-select-dropdown li {
  padding: 10px;
  cursor: pointer;
}

.nested-select-dropdown li ul {
  display: none;
  margin-left: 20px;
}

.nested-select-dropdown li.open ul {
  display: block;
}

.nested-select-dropdown li:hover {
  background-color: #f2f2f2;
}
.dropdown-container {
  padding: 10px;
  width: -webkit-fill-available;
  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;

  .select-container {
    width: -webkit-fill-available;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #b3b3b3;
  }
}

.select-dropdown-content {
  width: 90%;
  position: fixed;
  top: 10%;
  overflow: auto;
  height: 80%;
}

.dropdown-modal {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
.dropdwon-header {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* Neutral / Neutral-100 */

  color: #1a2631;
  margin-bottom: 10px;
}
