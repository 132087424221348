.session-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: 16px;
  width: 49%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(72, 79, 84, 0.2);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  .button-color {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #484f54 !important;
  }
  &:disabled {
    background-color: #f5f5f5 !important;
    cursor: not-allowed;
    transition: none;
    &:hover {
      transform: none;
    }
  }
}
