.reset-container {
  width: 100%;
  display: flex;
  height: 100vh;
  .reset-action-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .reset-body {
      width: 65%;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .reset-header-container {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        text-align: center;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        input {
          width: 100%;
          border: none;
          box-sizing: border-box;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          height: 44px;
          background-color: rgb(241, 241, 245);
          padding-left: 20px;
          border-radius: 1rem;
          &:focus {
            outline: none;
          }
        }
        .magic-code-input {
          z-index: 3;
        }
        .btn-container {
          width: 100%;
          margin-top: 16px;
          .continue-button {
            width: 130px;
            height: 32px;
            background: #1a2631;
            color: white;
            border-radius: 100px;
            border: none;
            cursor: pointer;
            display: block;
            font-weight: 600;
            float: right;
            padding-left: 0;
            font-size: 12px;
            min-width: 180px;
            &:disabled {
              background: #d2d7db;
              cursor: not-allowed;
            }
          }
        }
        .password-wrapper {
          width: 100%;
          position: relative;
          img {
            position: absolute;
            top: 10px;
            right: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
