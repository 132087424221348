.risk-issue-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  .risk-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    &.risk-wrapper-border {
      border-radius: 8px;
      padding: 18px;
      border: 1px solid #d0d5dd;
    }
    .custom-radio-groups {
      gap: 6px;
      .radio-item {
        border-radius: 8px;
        border: 1px solid rgba(99, 108, 115, 0.17);
        padding: 10px;
        label {
          font-weight: 400;
        }
        .labelClass {
          font-weight: 600;
          color: #363853;
        }
      }
    }
  }
  .issue-wrapper {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .primary-issue {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 0;
    }
    .secondary-issue {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 0;
    }
    .select-box {
      margin-top: 6px;

      .select-placeholder {
        justify-content: space-between;
        > span {
          width: 80%;
        }
      }
      .v2-dropdown {
        width: 100%;
        .dropdown-item {
          height: 3rem;
          > span {
            width: 80%;
          }
        }
      }
    }
  }
}
