.client-card-container {
  border: 1px solid #c5d9f8;
  padding: 16px;
  background-image: linear-gradient(to right, #f3f8ff, #c5d9f8);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .credits-container {
    min-width: 110px;
    padding: 10px;
    background-color: #829cc2;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 44px;
    cursor: pointer;
    gap: 5px;
  }
}
.request-credits-button {
  border-radius: 20px;
}
