@use '../../../configs/styles/defaultStyle.scss' as default;

.button-container {
  min-height: 32px;
  min-width: 180px;
  border-radius: 100px;
  padding: 0px 10px;
  text-align: center;
  font-family: Poppins;
  cursor: pointer;

  &.primary {
    background-color: default.$primary-color;
    border: none;
  }

  &.secondary {
    background-color: #fff;
    border: 1px solid default.$primary-color;
  }

  &:disabled:not(.label, .hyperlink) {
    background-color: #cfcbc7 !important;
    span {
      color: #fcfbf8;
    }
    border: none !important;
  }

  &.raised {
    min-height: 48px;
    min-width: 100px;
    border-radius: 8px;
    background-color: #fbfdff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border: 1px solid #e6edf2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img.icon {
      height: 15px;
      width: 15px;
      object-fit: contain;
      margin-right: 10px;
    }
  }

  &.caution {
    border: 1px solid default.$error-color;
    background-color: #fff;
    span {
      color: default.$error-color;
    }
  }

  &.blue {
    background-color: #263e58;
    border: none;
  }
  &.save {
    border: 1px solid default.$save-btn-border-color;
  }
  &.label {
    padding: 0 30px;
    &:hover {
      text-decoration: none !important;
    }
    &:disabled {
      background-color: none;
      span {
        color: #cfcbc7;
      }
      border: none !important;
    }
  }
  &.info {
    padding: 6px 10px;
    border-radius: 8px;
    border: none;
    color: #838383 !important;
    span {
      color: #838383;
    }
  }
  &.sos {
    border-radius: 30px;
    border: 1px solid #f77c7c;
    span {
      &.typography {
        color: #f77c7c !important;
      }
    }
  }
  &.hyperlink {
    padding: 0;
    color: #1c4679;
    text-decoration: underline;
  }
  &.inperson-default {
    border-radius: 8px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    span {
      font-size: 1rem !important;
    }
  }
  &.inperson-complete {
    border: none;
    min-height: 48px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: auto !important;
    img.icon {
      height: 15px;
      width: 15px;
      object-fit: contain;
      margin-right: 10px;
    }
    span {
      font-size: 1rem !important;
      color: #128410;
    }
  }
}
