@use '../../../configs/styles/defaultStyle.scss' as default;

.textbox-container {
  width: 100%;
  .textbox-action-container {
    width: 100%;
    width: 100%;
    position: relative;
    input {
      min-width: fit-content;
      height: 40px;
      background-color: default.$main-content-background-color;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      border: none;
      padding-left: 40px;
      padding-right: 20px;
      outline: none;
      width: 100%;
      box-sizing: border-box;

      &.normal {
        border-radius: default.$main-content-border-radius;
        ::placeholder {
          color: default.$secondary-color;
        }
      }

      &.box {
        border-radius: 10px;
        ::placeholder {
          color: #f1f1f5;
        }
      }

      &.solarized {
        border-radius: default.$main-content-border-radius;
      }

      &.box-border {
        border-radius: 0.25rem;
        border: 1px solid #dde4f0;

        &:focus {
          border: 1px solid #263e58;
        }
      }
    }

    img.validation-success {
      position: absolute;
      right: 16px;
      top: 50%;
      translate: 0 -50%;
    }
  }
  .error-message-container {
    width: 100%;
    margin-top: 0.5em;
  }
}
