@use '../../../configs/styles/defaultStyle.scss' as default;

.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;

  .action-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 2;
    align-items: flex-start;

    .logo-container {
      margin-left: 13%;
      margin-top: 15%;
    }

    .login-form {
      //   align-self: center;
      margin-top: 10%;
      width: 50%;
      margin-left: 13%;

      .login-header {
        margin: 5px 0;
      }

      .form-container {
        margin-top: 60px;
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 20px;
        .btn-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .input-password-wrapper {
          width: 100%;
          position: relative;
          img {
            position: absolute;
            top: 10px;
            right: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
