.banner-container {
  width: 100%;
  display: flex;
  padding: 21px 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  border-radius: 8px;
  .banner-left-section {
    width: 80%;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    .banner-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;
      justify-content: center;
      div {
        b {
          color: #6e5122;
        }
      }
    }
  }
  .banner-right-section {
    display: flex;
    gap: 0.2rem;
    align-items: center;
  }
  &.automated-msg {
    background-color: rgba(175, 109, 10, 0.05);
    border: 1px solid rgba(175, 109, 10, 0.32);
    height: 79px;
  }

  &.available {
    height: 54px;
    border: 1px solid #dde4f0 !important;
    background: #fbfcfe;
  }
  &.on-leave {
    height: 54px;
    border: 1px solid #e5deca;
    background: #fef9ec;
  }
  &.inperson {
    border: none;
    height: 72px;
    padding: 24px 90px;
    margin-top: 0;
    border-radius: 0;
    .banner-left-section {
      width: 100%;
    }
  }
  &.error {
    background: #db4256;
    height: 58px;
    padding: 1rem;
    margin-top: 0;
    span {
      color: white;
      width: 100% !important;
    }
    .banner-left-section {
      width: 100% !important;
      .banner-content {
        width: 100%;
      }
    }
    .banner-right-section {
      display: none;
    }
  }
  .tooltip-enable {
    cursor: pointer;
  }
  .banner-tooltip {
    border-radius: 8px;
    cursor: pointer;
    width: 283px;
    font-size: 12px;
    font-weight: 400;
    z-index: 2;
  }
}
