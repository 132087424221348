@mixin riskAssessmentBoxBorder($border-color: rgba(208, 213, 221, 1)) {
  border: 1px solid $border-color;
  border-radius: 8px;
}

@mixin flexColWithGap() {
  display: flex;
  flex-direction: column;
}

.risk-assessments-field {
  margin-top: 0.6rem;
  .radio-item {
    @include riskAssessmentBoxBorder();
    padding: 0.5rem;
    label {
      letter-spacing: 0;
      margin-top: 1px;
      font-size: 12px;
    }
  }
  .suicidal-assessments {
    margin-top: 8px;
    .button-container {
      min-width: 160px;
    }
  }
}

.in-session-followup {
  .suicidal-assessments-container {
    @include flexColWithGap();
    position: relative;
    top: 10px;
    .suicidal-assessments {
      @include flexColWithGap();
      .button-container {
        min-width: 160px !important;
        .typography {
          font-size: 15px !important;
        }
      }
    }
    .suicidal-assessments:first-child {
      margin-top: 0;
    }
    .suicidal-assessments:last-child {
      margin-bottom: 10px;
    }
  }
}
