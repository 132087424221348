.audio-preview {
  position: absolute;
  bottom: 100%;
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #3c566f;
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
  z-index: 3;

  img.discard-audio {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}
