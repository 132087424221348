@use '../../../configs/styles/defaultStyle.scss' as default;

.case-note-detail-item {
  box-sizing: border-box;
  padding: 17px 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid default.$divider-color;
  height: 80px;

  .case-note-left-pane {
    display: flex;
    flex-direction: column;
  }
}
