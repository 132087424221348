@use '../../../../../configs/styles/mixins.scss';

.date-picker-input {
  @include mixins.flex-col(center, flex-start);
  height: 2.8rem;
  min-width: 132px;
  padding: 0.525rem 0.725rem;
  position: relative;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;

  img.icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    translate: 0 -50%;
  }
}
