.error-boundary-container {
  height: 100vh;
  width: 100vw;
  margin: 5rem;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
}
