@use '../../../configs/styles/defaultStyle.scss' as default;
.editor-container {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: default.$primary-color;
  .editor-input-wrapper {
    height: 90px;
    overflow-y: scroll;
    .mentions {
      font-size: 12px;
      font-weight: 400;
    }
    .mentions--singleLine .mentions__control {
      display: inline-block;
    }
    .mentions--singleLine .mentions__input {
      border: 2px inset;
    }

    .mentions--multiLine .mentions__highlighter {
      color: blue;
      border: none !important;
    }
    .mentions--multiLine .mentions__input {
      outline: 0;
      border: 0;
      min-height: 90px;
    }

    .mentions__mention {
      position: relative;
      z-index: 1;
      color: blue;
      pointer-events: none;
    }
  }
  .mentions__suggestions {
    border-radius: 8px;
    border: 1px solid #f2f4f7;
    background: #fff;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  }

  .mentions__suggestions__list {
    border-radius: 8px;
    font-size: 16px;
    background-color: white;
  }
  .mentions__suggestions__list :first-child {
    border-radius: 8px 8px 0 0;
  }

  .mentions__suggestions__list :last-child {
    border-radius: 0 0 8px 8px;
  }

  .mentions__suggestions__item {
    width: 180px;
    padding: 10px 14px;
  }

  .mentions__suggestions__item--focused {
    background-color: #e9ecee;
    font-size: 16px;
  }
  .char-count-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .highlight-count {
      color: red;
    }
  }
}
