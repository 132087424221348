@use '../../../configs/styles/defaultStyle.scss' as default;

.client-details {
  width: 610px;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 30px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  img.close {
    position: absolute;
    top: 32px;
    right: 21px;
    cursor: pointer;
  }

  .details-container {
    box-sizing: border-box;
    padding: 0px 33px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 25px;

    .basic-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 13px;

      .client-name-and-tags {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
      }

      .client-type {
        height: 24px;
        padding: 3px 13px;
        background-color: rgba(26, 38, 49, 0.1);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .sessions-details {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;

      .detail-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-right: 1px solid default.$divider-color;
        padding-right: 20px;

        &:last-child {
          border: none;
        }
      }
    }
  }

  .elaborated-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .collapsible-items-header {
      padding-left: 31px;
      width: 100%;
      height: 40px;
      background-color: #e6edf2;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .case-notes {
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
    }
  }
  .in-session-followup {
    width: 100%;
  }

  .side-drawer-container {
    &.in-session-no-followup {
      width: 625px;
    }
  }
}
