@use '../../../configs/styles/defaultStyle.scss' as default;

.custom-radio-groups {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;

  .radio-item {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    height: 2.5rem;
    justify-content: flex-start;
    align-items: center;

    input[type='radio'] {
      height: 0.9rem;
      width: 1rem;
      accent-color: default.$primary-color;
      cursor: pointer;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: #636c73;
      cursor: pointer;
    }
  }
}

.radio-groups {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .radio-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    height: 2.5rem;
    justify-content: flex-start;
    align-items: center;

    input[type='radio'] {
      height: 1rem;
      width: 1rem;
      accent-color: default.$primary-color;
      cursor: pointer;
    }

    label {
      margin-top: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: #1a2631;
      cursor: pointer;
    }
  }
}
.deselected-label {
  margin-top: 4px;
  color: #c3c8cc;
  cursor: not-allowed;
}
