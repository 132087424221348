.cta-assignment-message {
  width: 230px;
  box-sizing: border-box;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;
  .button-container.disabled {
    background: #969594;
    cursor: not-allowed !important;
  }
}
