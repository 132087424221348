.client-selection-container {
  margin: auto;
  width: 65%;
  padding: 20px;
}

.client-selection-message {
  width: 60%;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 48px;
}

$data-list-column-spec: 1fr 1fr 0.75fr 1.5fr 1fr;

.client-list-container {
  box-shadow: 0px 0px 24px rgba(26, 38, 49, 0.08);
  border: 1px solid #c8cbd9;
  margin-top: 30px;
  height: 37.5vh;
  overflow-y: scroll;
}

.client-list {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  row-gap: 20px;
  column-gap: 1rem;
  padding: 1.3rem;
  cursor: pointer;
  user-select: none;

  .selected {
    // Add css here
    background-color: rgb(24, 26, 26);
  }
}

.client-button-container {
  width: 40%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 50px;
}

.cell-container {
  display: flex;
  justify-content: space-between;
}

.assign-client-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  .avatar {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
