@mixin flex-col($main-axis: flex-start, $cross-axis: flex-start) {
  display: flex;
  flex-direction: column;
  justify-content: $main-axis;
  align-items: $cross-axis;
}

@mixin flex-row($main-axis: flex-start, $cross-axis: flex-start) {
  display: flex;
  flex-direction: row;
  justify-content: $main-axis;
  align-items: $cross-axis;
}

@mixin selectable() {
  cursor: pointer;
  user-select: none;
}

@mixin data-section() {
  background: #fbfcfe;
  border: 1px solid #dde4f0;
  border-radius: 8px;
}
