@use '../../../configs/styles/mixins.scss';

.send-sos {
  @include mixins.flex-col(center, center);
  gap: 0.5rem;
  box-sizing: border-box;
  padding: 2rem;
  width: 523px;

  .send-sos-actions {
    @include mixins.flex-row(center, center);
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
  }
}
