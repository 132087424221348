.checkbox-wrapper {
  width: 100%;
  padding: 14px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
  section {
    display: flex;
    gap: 21px;
    align-items: center;
    justify-content: flex-start;
    label {
      color: #636c73;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Poppins';
    }
  }
}
