.tags-container {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 0.5rem;

  .tag {
    height: 24px;
    padding: 3px 13px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    width: max-content;
    align-items: center;

    &.normal {
      background-color: rgba(26, 38, 49, 0.1);
    }

    &.blue {
      background-color: #d7e8fc;
    }

    &.pill {
      background-color: #428bb5;
      border-radius: 100px;
      height: 20px;
    }
    &.disclaimer {
      background: #fff4e7;
      border-radius: 30px;
      height: 28px;
      gap: 5px;
      .icon {
        cursor: pointer;
      }
      .customTooltip {
        background: #fefaee;
        border-radius: 7px;
        color: #434444;
        width: 55%;
        z-index: 5;
        font-size: 13px;
        font-weight: 400;
        font-family: 'Poppins';
        padding: 1rem;
      }
    }
  }
}
