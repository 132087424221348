.information-wrapper {
  width: 35vw;
  height: fit-content;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
  .info-body {
    width: 95%;
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    background: #fefbf2;
    padding: 12px;
    &.custom-style {
      background: none;
      text-align: center;
      width: 85%;
    }
    .info-modal-icon {
      width: 10%;
      padding-top: 3px;
    }
    &.single-cta {
      background: none;
      text-align: center;
    }
    &.timezone-style {
      background: none;
      color: #808080;
    }
  }
  .info-modal-footer {
    width: 95%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    &.custom-style {
      flex-direction: row;
      width: 85%;
    }
  }
}

@media (max-width: 960px) {
  .information-wrapper {
    width: 100%;
  }
}
