header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #6b7785;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  height: 80px;
  margin-top: -3.75rem;

  a:visited {
    color: #1f2d3d;
  }

  a:focus {
    color: #1f2d3d;
  }

  a:active {
    color: #1bebb9;
  }

  a:hover {
    color: #1bebb9;
  }
}

header span {
  font-size: 12px;
  font-weight: 600;
}

header a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: #1f2d3d;
}

header a.new-tab-link {
  border: 1px solid #6b7785;
  border-radius: 8px;
}

.header-section {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
