@use '../../../../configs/styles/defaultStyle.scss' as default;

$no-of-waves: 15;

.recording-wave-container {
  position: relative;

  .recording-wave {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;

    span {
      height: 0px;
      width: 7px;
      border-radius: 20px;
      margin-right: 10px;
      background-color: default.$primary-color;
      animation: up-and-down 1s linear infinite;

      @for $i from 1 through $no-of-waves {
        &:nth-child(#{$i}) {
          animation-delay: calc(0.1s * #{$i});
        }
      }
    }
  }
}

@keyframes up-and-down {
  0% {
    height: 0;
  }
  25% {
    height: 15px;
  }
  50% {
    height: 35px;
  }
  100% {
    height: 0;
  }
}
