@tailwind base;
@tailwind components;
@tailwind utilities;

.client-pagination-item {
  @apply p-3 h-8 text-center mx-1 my-auto text-gray-900 flex box-border items-center tracking-tighter rounded-full leading-5 text-xs min-w-[32px] hover:bg-gray-200 hover:cursor-pointer font-medium;
}

.client-pagination-item-selected {
  @apply bg-primary-600 text-white font-semibold hover:bg-primary-600 hover:text-white;
}

.client-pagination-arrow-btn {
  @apply flex items-center gap-0.5 font-semibold cursor-pointer;
}

.client-pagination-arrow-btn-disabled {
  @apply text-gray-300 pointer-events-none cursor-none;
}

.client-pagination-dots:hover {
  @apply cursor-default;
  background-color: transparent;
}
