.backdrop-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  right: 0;

  &.dark {
    background-color: rgba($color: #000000, $alpha: 0.2);
  }

  &.glass {
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background-color: rgba(26, 38, 49, 0.58);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
    }

    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background-color: rgba(26, 38, 49, 0.58);
    }
  }
}
