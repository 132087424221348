.media-viewer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  background-color: hsla(0, 0, 0, 75%);
  z-index: 11;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img.close {
    position: absolute;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    width: 3rem;
    height: auto;
  }

  img.media {
    max-height: 85%;
    width: auto;
    max-width: 90%;
    object-fit: contain;
  }
}
