@mixin flex($justifyContent: space-between, $alignItems: center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}
@mixin followUpBoxBorder($border-color: rgba(208, 213, 221, 1)) {
  border: 1px solid $border-color;
  border-radius: 8px;
}

.followUp {
  @include flex();
  flex-direction: column;
  .followUpSessionSelector {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    @include flex();
    @include followUpBoxBorder();
    .section-title {
      margin: 0;
      padding: 0;
    }
  }
  .followUpPlanDocumentation {
    width: 100%;
  }
}
