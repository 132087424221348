.voice-recorder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  width: 100%;

  .recorder-control {
    height: 1.5rem;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      object-fit: contain;
      cursor: pointer;

      &.cancel {
        height: 1.5rem;
      }

      &.confirm {
        height: 1rem;
      }
    }
  }
}
