.side-drawer-container {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: fit-content;
  z-index: 10;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  overflow-y: auto;

  .control {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 10;
  }

  &.open {
    transform: translateX(0);
  }
}
