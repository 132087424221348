@mixin flex($justifyContent: space-between, $alignItems: center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

.presentingIssues {
  @include flex();
  gap: 1rem;
  .primary-issues,
  .secondary-issues {
    display: flex;
    flex-direction: column;
  }
  .primary-issues {
    flex-basis: 40%;
    .v2-dropdown {
      width: 100%;
    }
  }
  .secondary-issues {
    flex-basis: 60%;
    .multi-select-container {
      height: 2.75rem;
      .select-list {
        width: 100%;
      }
    }
  }
  .section-title {
    height: 36.95px;
  }
  .multi-dropdown-wrapper {
    margin-top: 7px;
  }
}
.in-session-followup {
  .presentingIssues {
    gap: 0;
    flex-wrap: wrap;
    .primary-issues {
      flex-basis: 100%;
    }
    .secondary-issues {
      flex-basis: 100%;
    }
  }
  .multi-dropdown-wrapper {
    margin-top: 7px;
  }
}
