@use '../../../configs/styles/defaultStyle.scss' as default;

.collapsible-container {
  width: 100%;
  min-height: 40px;
  height: auto;
  position: relative;
  transition: height 0.5s ease;
  overflow: hidden;
  border-bottom: 1px solid default.$divider-color;

  .collapsed-header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .collapsible-controls {
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    right: 5px;
    top: 5px;
    height: auto;
    span.control-icon {
      // TODO need to make dynamic controls based on collapsedMinHeightProp
      &.large {
        right: 36px;
        top: 8px;
      }

      &::after {
        content: '';
        border-right: 2px solid default.$secondary-color;
        border-top: 2px solid default.$secondary-color;
        position: relative;
        display: inline-block;
        width: 5px;
        height: 5px;
        transform: rotate(-45deg);
        transition: all ease 300ms;
      }

      &.large {
        &::after {
          width: 7px;
          height: 7px;
        }
      }

      &.expanded::after {
        transform: rotate(135deg);
        border-right: 2px solid default.$primary-color;
        border-top: 2px solid default.$primary-color;
      }
    }
  }

  .collapsible-content-container {
    height: auto;
    width: auto;
    overflow: auto;
  }
}
