@use '../../../../configs/styles/defaultStyle.scss' as default;

.chat-contact-container {
  width: 102%;
  height: 70px;
  padding: 13px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid default.$divider-color;

  &.selected {
    background-color: #e6edf2;
  }

  .left-pane {
    margin-right: 15px;
  }

  .chat-main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 172px;

    .message-container {
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .chat-indication-container {
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    .heartbeat-indicator {
      width: 20px;
      height: 20px;
      text-align: center;
      font-size: 12px;
      color: white;
      border-radius: 50%;
      font-weight: 600;
      padding-top: 1px;

      &.not-consumed {
        background-color: #1d9c48;
      }

      &.consumed {
        background-color: transparent;
      }
    }
  }
}
