.group-editor-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  .group-text-editor-wrapper {
    background: #fbfcfe;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    padding: 13px 19px;
    margin-top: 7px;
    .custom-style {
      .editor-input-wrapper {
        min-height: 70px;
        overflow-y: auto;
        .mentions {
          font-size: 14px;
        }
      }
    }
  }
}
