@use '../../../../../configs/styles/mixins.scss';

.picker-popper {
  @include mixins.flex-col;
  position: fixed;
  width: max-content;
  height: max-content;
  padding: 1.25rem;
  background-color: #fff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  z-index: 10;
  user-select: none;
  box-sizing: border-box;

  .picker-navigation {
    @include mixins.flex-row(space-between, center);
    width: 100%;
    height: 2.25rem;
    padding: 0 40px;
    margin-bottom: 1rem;
    box-sizing: border-box;

    img.navigation {
      cursor: pointer;
    }
  }

  .calender-days {
    display: grid;
    grid-template-columns: repeat(7, 60px);
    grid-template-rows: repeat(6, 60px);

    .calender-day {
      @include mixins.flex-col(center, center);
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &.selected {
        background-color: #263e58;
      }

      &.enable-marker {
        &::after {
          content: '';
          height: 5px;
          width: 5px;
          background-color: green;
          border-radius: 50%;
        }
      }
    }
  }

  .selected-day {
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  .date-picker-action {
    @include mixins.flex-row(space-evenly, center);
    width: 100%;
    height: 4.5rem;
    border-top: 1px solid #eaecf0;
  }
}
