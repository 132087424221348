.beacon-select-box {
  height: 2.75rem;
  min-width: 132px;
  box-sizing: border-box;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.disabled {
    background-color: rgba(224, 224, 224, 0.12);
  }

  .placeholder-container {
    width: 100%;
    user-select: none;

    .select-placeholder {
      margin: 0.525rem 0.725rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: center;
      gap: 1rem;
    }
  }

  .v2-dropdown {
    position: absolute;
    left: 0px;
    max-height: 280px;
    min-width: 180px;
    min-height: max-content;
    overflow: auto;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 0px 24px rgba(26, 38, 49, 0.08);
    border-radius: 0.5rem;

    .dropdown-item {
      width: 100%;
      box-sizing: border-box;
      height: 2.25rem;
      padding: 0.75rem 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0.75rem;
      background-color: transparent;
      border-bottom: 1px solid #dde4f0;

      &.selected {
        background-color: rgb(233, 236, 238);
      }
    }
  }
}
