@use '../../../../configs/styles/defaultStyle.scss' as default;
.chat-header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  height: 75px;
  border-bottom: 1px solid default.$divider-color;

  .user-info-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    &.hideUserInfo {
      visibility: hidden;
    }

    .image-profile-container {
      height: 44px;
      width: 44px;
      border-radius: 50%;
      border: 2px solid default.$primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.4rem;
      Button {
        padding: 0;
        &.raised {
          justify-content: flex-end;
          box-shadow: none;
          background-color: #fff;
        }
        &:hover {
          text-decoration: underline;
        }
        img {
          margin-left: 5px;
          filter: brightness(0) saturate(100%) invert(8%) sepia(8%)
            saturate(3817%) hue-rotate(167deg) brightness(94%) contrast(85%);
        }
      }

      .user-tags-and-name {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
      }

      .user-type {
        height: 18px;
        width: auto;
        padding: 1px 9px;
        border-radius: 4px;
        background-color: #f1f1f5;
      }
    }
  }

  .toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .tool-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      padding: 6px;
    }
  }
}
