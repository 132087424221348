@mixin flex($justifyContent: space-between, $alignItems: center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

.interventionModality {
  section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }
  .primary {
    width: 100%;
    .v2-dropdown {
      width: 100%;
    }
  }
}
